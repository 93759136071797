<template>
  <div class="contianer">
      <div class="head">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <div class="title">{{this.id==-1?'新增':'编辑'}}文章<span v-if="loadling" style="font-size:18px;color:#4D97FF;margin-left:5px" class="el-icon-loading"></span></div>
      </div>
      <div class="from">
            <div class="left">
            <div class="label-box">
              <label>文章标题</label>
              <el-input v-model="titleValue" size="small" placeholder="请输入文章标题"></el-input>
            </div>
            <div class="label-box">
                <label>文章类型</label>
                <el-select v-model="articType" size="small" placeholder="请选择">
                        <el-option
                            v-for="item in articTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
            </div>
            <div class="label-box">
                <label>启用状态</label>
                <el-select v-model="statusValue" size="small" placeholder="请选择">
                        <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
            </div><div class="label-box">
              <label>备注内容</label>
              <el-input style="width:80%" type="textarea" v-model="noteValue" :rows="3" placeholder="请输入内容"></el-input>
           </div>
            <div class="label-box">
              <label>概要内容</label>
              <el-input v-model="gaiyaoValue" style="width:80%;" type="textarea" :rows="9" placeholder="请输入文章列表展示时的概要内容"></el-input>
            </div>
           <div class="label-box">
                <label>概要图片</label>
                <div class="img-box">
                    <el-upload
                        action="./assets/images"
                        list-type="picture-card"
                        :limit="limit"
                        ref="upload"
                        :file-list="fileList"
                        :on-change="getFile"
                        :auto-upload="false">
                        <template #default>
                            <i class="el-icon-plus"></i>
                        </template>
                        <template #file="{file}">
                        <div>
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                            <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <!-- <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                            >
                                <i class="el-icon-download"></i>
                            </span> -->
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                            </span>
                        </div>
                        </template>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </div>
            </div>
          </div>
          <div class="right">
              <div class="label-box" style="flex:1">
                <label style="margin-right:1%">文章内容</label>
                <quill-editor class="ql-editor"  v-model="contentValue" ref="myQuillEditor" ></quill-editor>
            </div>
            <div class="btn-box">
               <el-button type="warning" @click="goBack">取消</el-button>
               <el-button type="primary" @click="save">保存</el-button>
           </div>
          </div>
      </div>
  </div>
</template>

<script>
import addQuillTitle from '../assets/js/quill.title.js';
export default {
    data(){
        return{
            id:-1,
            limit:1,
            gaiyaoValue:'',
            titleValue:'',
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            contentValue:'',
            statusValue:'',
            statusOptions:[{label:'启用',value:1},{label:'禁用',value:0}],
            noteValue:'',
            articType:'',
            articTypeOptions:[{label:'行业动态',value:0},{label:'案例展示',value:1}],
            summaryPicture:'',
            fileList: [],
            loadling:false
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
        handleRemove(file) {
            this.$refs.upload.clearFiles();
            this.summaryPicture = '';
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            
        },
        //获取文章内容
        getArticle(){
            this.loadling = true
            this.$api.getArticle({id:this.id}).then(res=>{
                this.loadling = false
                if(res.data.code == 200){
                    this.titleValue = res.data.data[0].title
                    this.articType = res.data.data[0].type
                    this.statusValue = res.data.data[0].state
                    this.contentValue = res.data.data[0].content
                    this.gaiyaoValue = res.data.data[0].summaryContent
                    this.summaryPicture = res.data.data[0].summaryPicture
                    this.noteValue = res.data.data[0].remark
                    this.fileList = [];
                    this.fileList.push({
                        name:'',
                        url:res.data.data[0].summaryPicture
                    })
                }
            })
        },
        //图片转base64
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                };
                reader.onerror = function(error) {
                    reject(error);
                };
                reader.onloadend = function() {
                    resolve(imgResult);
                };
            });
        },
        //上传图片
        getFile(file, fileList) {
            this.getBase64(file.raw).then(res => {
                this.summaryPicture = res;
            })
        },
        save(){
            if(this.titleValue == '' || (Number(this.articType) !==0 && Number(this.articType) !==1) ||( Number(this.statusValue) !==0 && Number(this.statusValue) !==1) || this.gaiyaoValue == '' || this.summaryPicture == '' || this.contentValue == ''){
                this.$message({
                    message: '请检查表单是否输入完整！',
                    type: 'error'
                })
                return;
            }
            this.$api.addArticle({
                title: this.titleValue,
                type: this.articType,
                state: this.statusValue,
                content: this.contentValue,
                summaryContent: this.gaiyaoValue,
                summaryPicture: this.summaryPicture,
                remark: this.noteValue,
                isEdit: this.id==-1?'':'isEdit',
                id: Number(this.id)
            }).then(res=>{
                if(res.data.code == 100){
                    this.$message({
                        message: res.data.message,
                        type: 'error'
                    })
                }else{
                    this.$message({
                        message: res.data.message,
                        type: 'success'
                    });
                    this.$router.push('/showManagement')
                }
            })
        }
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
        if(Number(this.$route.query.id) !== -1){
            this.id = this.$route.query.id
        }
    },
    mounted(){
        addQuillTitle();
        if(Number(this.id)!== -1){
            this.getArticle();
        }
    },
}
</script>

<style lang="scss" scoped>
.contianer{
    background: #fff;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    padding: 0.7rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.head{
    height: 4%;
    display: flex;
    align-items: center;
    position: relative;
    i{
        position: absolute;
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
    }
    .title{
        width: 100%;
        text-align: center;
        color: #4D4D4D;
        font-weight: bold;
        font-size: 17px;
        letter-spacing: 2px;
    }
}
.from{
    flex: 1;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .left{
        width: 30%;
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .label-box{
        display: flex;
        align-items: flex-start;
        margin: 15px 0;
        label{
            color: #4D4D4D;
            font-size: 15px;
            margin-right: 2%;
            white-space: nowrap;
        }
        .el-input,.el-select{
            width: 80%;
        }
        .img-box{
            width: 100%;
            height: 18rem;
            max-width: 100%;
            max-height: 18rem;
            overflow-y:auto;
        }
    }
    .btn-box{
        height: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-button{
            margin: 0 1.875rem;
        }
    }
}
</style>